import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';
import { LanguageDetectorModule } from 'i18next';

import en from '../assets/i18n/locales/en.json';
import tamil from '../assets/i18n/locales/tamil.json';
import telugu from '../assets/i18n/locales/telugu.json';
import marathi from '../assets/i18n/locales/marathi.json';
import konkani from '../assets/i18n/locales/konkani.json';
import urdu from '../assets/i18n/locales/urdu.json';
import arabic from '../assets/i18n/locales/arabic.json';
import gujrati from '../assets/i18n/locales/gujrati.json';
import hindi from '../assets/i18n/locales/hindi.json';
import kannada from '../assets/i18n/locales/kannada.json';

export function switchLanguage(lang: string) {
  localStorage.setItem('lang', lang);
  window.location.reload();
}

const LanguageDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  detect: () => {
    const lang = localStorage.getItem('lang') || 'en';
    localStorage.setItem('lang', lang);
    return lang;
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

export function appInit(i18next: ITranslationService) {
  return () =>
    i18next.use(LanguageDetector).init({
      resources: {
        en: { translation: en },
        tamil: { translation: tamil },
        telugu: { translation: telugu },
        marathi: { translation: marathi },
        konkani: { translation: konkani },
        urdu: { translation: urdu },
        arabic: { translation: arabic },
        gujrati: { translation: gujrati },
        hindi: { translation: hindi },
        kannada: { translation: kannada },
      },
      // supportedLngs: ['en', 'tamil'],
      fallbackLng: 'en',
      debug: true,
      returnEmptyString: false,
      // ns: ['translation', 'validation', 'error'],
    });
}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];
