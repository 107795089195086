<div class="">
  <app-auth-header></app-auth-header>


  <router-outlet></router-outlet>
</div>
<div class="container-fluid" id="increaseFont" style="background-color: #faf9fa;">
  <div class="row align-items-center banner-sidebar customVh">
  <div class="col-md-12" *ngIf="hideForSandbox">
    <div class="link">
      Check out <a href="https://einvoice6.gst.gov.in/login" target="_blank">IRIS IRP Live Portal</a>
      &bull; &nbsp;
      <select (change)="changeLanguage($event)" class="language-switch" [value]="i18nextSvc.language">
        <option value="en">English</option>
        <option value="marathi">Marathi</option>
        <option value="konkani">Konkani</option>
        <option value="urdu">Urdu</option>
        <option value="arabic">Arabic</option>
        <option value="tamil">Tamil</option>
        <option value="gujrati">Gujarati</option>
        <option value="hindi">Hindi</option>
        <option value="kannada">Kannada</option>
        <option value="telugu">Telugu</option>
      </select>
    </div>
</div>
    <div class="col-lg-5 text-center diplayFooter">
      <img src="assets/images/Infographic.png" class="img-fluid" />
      <div class="row">
        <div class="col-12  px-lg-5 text-lg-start">
          <h6>{{ 'label.IRIS IRP for an easy' | i18next }} </h6>
          <ul>
            <li>{{ 'label.Manage e-invoicing' | i18next }}</li>
            <li>{{ 'label.Bulk IRN generation' | i18next }}</li>
            <li>{{ 'label.Multiple modes' | i18next }}</li>
            <li>{{ 'label.Simple and intuitive' | i18next }}</li>
          </ul>
          <h6>{{ 'label.Sign-up and explore' | i18next }}</h6>
        </div>
      </div>
    </div>
    <div class="col-lg-7" style="margin-top: 25px;">
      <div class="card border-0 shadow py-4 px-3 mx-auto" style="max-width: 33.5rem;">
        <div class="card-body">
          <form [formGroup]="signUpForm" (ngSubmit)="signUp()" *ngIf="!signUpSuccess">
            <h2 class="card-title mb-3" style="font-weight: 700 !important; color: #252525;">{{ 'label.Sign Up' | i18next }}</h2>
            <div class="row signupBox">
              <div class="col-12" *ngIf="hideForSandbox">
                <label class="mb-1"><b>{{ 'new.Sandbox for Taxpayer' | i18next }}</b></label>
              </div>

              <div class="col-12">
                <label class="mb-1"><b>{{ 'label.User Type' | i18next }}</b></label>
              </div>
              <div class="col-12 mb-3">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="inlineRadio1" checked="checked" name="role"
                    formControlName="role" value="TAXPAYER">
                  <label class="form-check-label" for="inlineRadio1">{{ 'label.TaxPayer' | i18next }}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio"  id="inlineRadio2" name="role" formControlName="role"
                    value="API_INTEGRATOR">
                  <label class="form-check-label" for="inlineRadio2">{{ 'label.API Integrator' | i18next }}</label>
                </div>
              </div>
              <div class="col-12 small text-muted mb-3">
                <i class="bi bi-info-circle-fill me-2"></i> <span
                  *ngIf="signUpForm && signUpForm.controls.role.value == 'TAXPAYER'">{{ 'label.Businesses who need' | i18next }}
                </span>
                <span *ngIf="signUpForm && signUpForm.controls.role.value == 'API_INTEGRATOR'">{{ 'label.Solution Providers' | i18next }}
                </span>
              </div>
              <div class="col-12 mb-3">
                <label for="userName" class="form-label"><b>{{ 'label.Your Name' | i18next }}</b></label>
                <input type="text" class="form-control" placeholder="{{ 'label.Your Name' | i18next }}" id="userName" maxlength="250"
                  formControlName="userName">

                <div class="error"
                  *ngIf="submitted && signUpForm.controls.userName?.errors && signUpForm.controls.userName?.errors?.required">
                  {{ 'validations.Username is required' | i18next }}
                </div>
              </div>
              <div class="col-12 mb-3">
                <label for="email" class="form-label"><b>{{ 'label.Email id' | i18next }}</b></label>
                <input type="email" class="form-control" placeholder="{{ 'label.Preferably Work Email Id' | i18next }}" id="email" maxlength="250"
                  formControlName="email" [title]="toolTip()">

                <div class="error"
                  *ngIf="submitted && signUpForm.controls.email?.errors && signUpForm.controls.email?.errors?.required">
                  {{ 'validations.valid email id' | i18next }}
                </div>

                <div class="error"
                  *ngIf="submitted && signUpForm.controls.email?.errors && signUpForm.controls.email?.errors?.pattern">
                  {{ 'validations.valid email id' | i18next }}
                </div>

              </div>
              <div class="col-12 mb-3">
                <label class="custom_checkbox form-check-label textAlign">{{ 'label.By signing up' | i18next }}
                  <a routerLink="/terms-conditions" class="anchorLink" target="_blank">{{ 'label.Terms of service' | i18next }}</a>
                  {{ 'label.and' | i18next }}
                  <a routerLink="/privacy" class="anchorLink" target="_blank"> {{ 'label.Privacy Policy' | i18next }}</a>
                  <input type="checkbox" checked="checked" [value]="acceptTermsAndConditions"
                    (change)="acceptTermsAndConditions = !acceptTermsAndConditions" />
                  <span class="checkmark"></span>
                </label>
              </div>

              <div class="mb-3 row">

              </div>
              <div class="col-12 my-3 text-center">
                <button type="submit" class="btn btn-dark px-5 py-2"> {{ 'label.Sign Up' | i18next }}</button>
              </div>
              <div class="col-12 text-center">
                <p>{{ 'label.Already acc' | i18next }}? <span class="text-primary"><a routerLink="/login" class="anchorLink">{{ 'label.Log In' | i18next }}</a></span></p>
              </div>
            </div>
          </form>
        </div>
        <div class="card-body" *ngIf="signUpSuccess">

          <form [formGroup]="OTPForm">
            <h2 class="card-title mb-2" style="font-weight: 700 !important; color: #252525;">{{ 'label.OTP Verification' | i18next }}</h2>
            <div class="row">
              <div class="col-12 mb-5">
                <b>{{ 'label.We sent OTP' | i18next }} :{{maskMobileNumber()}}</b>
              </div>
              <div class="col-12 mb-3">
                <label for="userName" class="form-label"><b>{{ 'label.OTP' | i18next }} <sup
                  class="clrMandate">*</sup></b></label>
                  <input type="text" placeholder="" class="form-control" id="otp" name="otp"  minLength="6" onKeyPress="if(this.value.length==6) return false;"  formControlName="otp" />
                <small class="text-muted">{{ 'label.OTP will remain' | i18next }}</small>
              </div>
              <div class="content-center">
                <button type="submit" class="btn btn-dark px-5 py-2" [disabled]="!OTPForm.controls['otp'].valid" (click)="verifyOTP()">
                  {{ 'label.Validate OTP' | i18next }}
                </button>
              </div>
            <div class="col-12 text-center">
                <p class="mb-0 mt-2">Didn't receive an OTP? </p>
                <a (click)="sendOTP()" class="text-decoration-none custom-text anchorLink mt-2 cursor-pointer">{{ 'label.Resend OTP' | i18next }}</a>
            </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid footer">
  <div class="row footerDiv">
    <div class="col-md-9">
      <h3 class="display-4 text-white">{{ 'new.IRIS IRP is govt authorised E-Invoice Registration Portal' | i18next }}</h3>
    </div>
    <div class="col-md-3">
      <a href="https://einvoice6.gst.gov.in/content/contact-us/" target="blank"
        class="btn btn-primary rounded mb-0 text-nowrap">{{ 'label.Contact Us' | i18next }}</a>
    </div>
    <hr class="hrdiv">
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">{{ 'new.Important links' | i18next }}</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://www.gstn.org.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.GSTN' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://cbic-gst.gov.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.CBIC' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisirp.com/notifications/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.GST notifications' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://www.india.gov.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.National Portal' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://einvoice6.gst.gov.in/content/wp-content/uploads/2023/04/Letter-ON-NEW-IRPS-20April23.pdf"
            target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.GSTN Authorization of IRIS IRP' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://sandbox.einvoice6.gst.gov.in/login" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS IRP Sandbox' | i18next }}</a>
        </li>
      </ul>
    </div>
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">{{ 'new.IRIS IRP Policies' | i18next }}</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://einvoice6.gst.gov.in/content/terms-and-conditions/">{{ 'new.Terms and Condition' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://einvoice6.gst.gov.in/content/privacy/">{{ 'new.Privacy Policy' | i18next }}</a>
        </li>

      </ul>

    </div>
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">{{ 'new.About IRIS' | i18next }}</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisirp.com/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS IRP' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS GST' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisbusiness.com/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS Business' | i18next }}</a>
        </li>

      </ul>
      <h4 class="widget-title text-white mb-3">{{ 'new.Need Help' | i18next }}</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="mailto:support@irisirp.com">{{ 'new.Write to: support@irisirp.com' | i18next }}</a>
        </li>
      </ul>
    </div>
    <div class="col-md-3">
      <h4 class="widget-title text-white mb-3">{{ 'new.Other Products' | i18next }}</h4>
      <ul class="text-white" style="list-style: none; padding-left: 0px;">
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;"> <a
            href="https://irisgst.com/irissapphire/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS GST Software' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/iris-onyx/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS E-Invoicing' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/gst-litigation-management-system/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS Litigation Management' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/iristopaz/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS E-Way Bill' | i18next }}  </a>
        </li>

        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/iris-lookup/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS LookUp' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://irisgst.com/irisperidot/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.IRIS Peridot' | i18next }}</a>
        </li>
        <li style="color:#fff; font-size: 16px; font-weight: 400;padding-bottom: 15px;">
          <a href="https://easywaybill.in/" target="blank"
            title="This is an external link that opens in a new tab. You will be moving out of einvoice6.gst.gov.in website">{{ 'new.EWB app for transporters' | i18next }}</a>
        </li>
      </ul>
    </div>
    <hr class="hrdiv mb10">
    <div class="row">
      <div class="col-md-3">
        <div class="social social-white social-center">
          <span><a href="https://twitter.com/IRIS_IRP" target="blank"><i
                style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                class="bi bi-twitter"></i></a></span>
          <span><a href="https://www.linkedin.com/showcase/82795027/admin/" target="blank"><i
                style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                class="bi bi-linkedin"></i></a></span>
          <span><a href="https://www.youtube.com/@irisirp" target="blank"><i
                style="color:#fff; font-size: 18px;     padding-right: 16px; font-weight: 400;padding-bottom: 15px;"
                class="bi bi-youtube"></i></a></span>
        </div>
      </div>
      <div class="col-md-6">
        <p class="text-center text-white">© {{ 'new.2023 IRIS Business Services Ltd' | i18next }}.</p>
      </div>
      <div class="col-md-3 social-center">
        <span><img class="responsive" style="height: 40px;width: 100px;"
            src="../../../../assets/images/brands/sslcertificate.png" alt=""></span>
        <span><img class="responsive" style="height: 40px;width: 100px;" src="../../../../assets/images/brands/iso.png"
            alt=""></span>

      </div>
    </div>
  </div>
</div>
